<!--
 * @Author: your name
 * @Date: 2021-01-30 09:36:31
 * @LastEditTime: 2021-02-01 18:30:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /web-pc/src/components/common/Load.vue
-->
<template>
    <div class="loading">
        <a-spin :spinning="loading">
            <template #indicator>
                <Loading />
            </template>
        </a-spin>
    </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import Loading from '@/components/Alert/Loading.vue';
import { Spin } from 'ant-design-vue';

export default {
  name: 'Load',
  components: {
    Loading,
    ...importComponents(
      Spin,
    ),
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    .loading {
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        min-height: 767px;
        background-color: #f4f4f4;
        z-index: 10;
        border-bottom: 3px solid #e1e1e1;
    }
</style>
