
import importComponents from '@/utils/import-components';
import Loading from '@/components/Alert/Loading.vue';
import { Spin } from 'ant-design-vue';

export default {
  name: 'Load',
  components: {
    Loading,
    ...importComponents(
      Spin,
    ),
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
