<template>
  <div class="loader">
    <svg height="100" width="100">
      <circle cx="50" cy="50" fill="none" r="25"></circle>
    </svg>
  </div>
</template>
<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loading',
  components: {
    ...importComponents(),
  },
  setup() {
    return {};
  },
});
</script>
<style lang="scss">
.loader {
  margin: auto;
}
.loader svg {
  z-index: 2;
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}
.loader circle {
  stroke: #000;
  stroke-width: 2px;
  stroke-linecap: round;
  -webkit-animation: circle 1.5s ease-in-out infinite;
  animation: circle 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes circle {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes circle {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
