
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loading',
  components: {
    ...importComponents(),
  },
  setup() {
    return {};
  },
});
